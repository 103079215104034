import { COMPANY } from "redux/reducers/entities/companies/actionNames"
import toastTypes from "constants/toastTypes"
import exceptions from "constants/exceptions"
import { INTEGRATION } from "redux/reducers/entities/integrations/actionNames"

import {
    CREATE,
    DELETE,
    UPDATE,
    DUPLICATE,
    CLONE,
    REPUBLISH,
    MOVE,
    REORDER,
    SELECT,
    PUBLISH,
    UNPUBLISH,
    ACTIVATE,
} from "redux/utility"
import { ERROR, LOADED } from "redux/middleware/actionNames"

// CORE
import { LOGOUT } from "redux/reducers/core/authentication/actionNames"

// ENTITIES
import {
    INVITE,
    INVITE_RESEND,
} from "redux/reducers/entities/invites/actionNames"
import { JOURNEY } from "redux/reducers/entities/journeys/actionNames"
import { WORKSPACE } from "redux/reducers/entities/workspaces/actionNames"
import {
    ADD_MESSAGE,
    DELETE_CONTENT,
    JOURNEY_STEP,
    JOURNEY_STEPS,
    MOVE_CONTENT,
} from "redux/reducers/entities/journeySteps/actionNames"
import { TODO } from "redux/reducers/entities/todo/actionNames"
import { MESSAGE } from "redux/reducers/entities/messages/actionNames"
import { TOPIC, TOPICS } from "redux/reducers/entities/topics/actionNames"
import {
    STORY,
    STORIES,
    INTRODUCTION,
} from "redux/reducers/entities/stories/actionNames"
import { MESSAGE_TEMPLATE } from "redux/reducers/entities/messageTemplates/actionNames"
import { PAGE, PAGES } from "redux/reducers/entities/pages/actionNames"
import {
    USER,
    CHANGE_PASSWORD,
    MULTIPLE,
    USER_RELATIONS,
} from "redux/reducers/entities/users/actionNames"
import { THEME, THEMES } from "redux/reducers/entities/themes/actionNames"
import {
    GLOSSARY_ITEM,
    GLOSSARY_ITEMS,
} from "redux/reducers/entities/glossaryItems/actionNames"
import { TAG, TAGS } from "redux/reducers/entities/tags/actionNames"
import { JOURNEY_EVENT } from "redux/reducers/entities/journeyEvents/actionNames"
import { EMBEDS, EMBED } from "redux/reducers/entities/embedly/actionNames"
import { PREREQUISITE } from "redux/reducers/entities/prerequisites/actionNames"

// VENDORS
import { SMART_RECRUITERS } from "redux/reducers/vendors/smartRecruiters/actionNames"

import { SHOW_TOAST, DISMISS_TOAST } from "./actionNames"
import { selectData, selectIdentifiers } from "./selectors"
import {
    createToastReducer,
    createPrerequisiteToast,
    createJourneyStepToast,
    createMoveTodoToast,
    createMoveContentToast,
    createPrerequisiteDeleteToast,
} from "./helpers"

// Required variables
export const initialState = {
    /* Holds the normalized toasts redux object */
    data: {},
    /* Holds the identifiers for the toasts */
    identifiers: [],
}

export const reducers = {
    [SHOW_TOAST](state, payload) {
        const messages = selectData(state)
        const identifiers = selectIdentifiers(state)
        const { identifier } = payload
        return {
            ...state,
            data: {
                ...messages,
                [identifier]: payload,
            },
            identifiers: [...identifiers, identifier],
        }
    },

    [DISMISS_TOAST](state, payload) {
        if (!payload || !payload.identifier) {
            return initialState
        }

        const { identifier } = payload
        const messages = selectData(state)
        const identifiers = selectIdentifiers(state)

        delete messages[identifier]

        return {
            ...state,
            data: { ...messages },
            identifiers: identifiers.filter(id => id !== identifier),
        }
    },

    [COMPANY + SELECT]() {
        return initialState
    },

    [LOGOUT + LOADED]() {
        return initialState
    },

    /* Respond with error toast to failed requests */
    // [JOURNEYS + ERROR]: createToastReducer("main:generic_error", toastTypes.error),
    [JOURNEY + CREATE + ERROR]: createToastReducer(
        "main:generic_request_error",
        toastTypes.error,
        { entity: "Journey", action: "creating" },
    ),
    [JOURNEY + DUPLICATE + ERROR]: createToastReducer(
        "main:generic_request_error",
        toastTypes.error,
        {
            entity: "Journey",
            action: "duplicating",
        },
    ),
    [JOURNEY + DELETE + ERROR]: createToastReducer(
        "main:generic_request_error",
        toastTypes.error,
        { entity: "Journey", action: "deleting" },
    ),
    [JOURNEY + UPDATE + ERROR]: createToastReducer(
        "main:generic_request_error",
        toastTypes.error,
        { entity: "Journey", action: "renaming" },
    ),
    [JOURNEY + PUBLISH + ERROR]: createToastReducer(
        "main:publish_journey_error",
        toastTypes.error,
    ),
    [JOURNEY + UNPUBLISH + ERROR]: createToastReducer(
        "main:unpublish_journey_error",
        toastTypes.error,
    ),
    [JOURNEY + REPUBLISH + ERROR]: createToastReducer(
        "main:republish_journey_error",
        toastTypes.error,
    ),
    [JOURNEY + MOVE + ERROR]: createToastReducer(
        "main:move_journey_error ",
        toastTypes.error,
    ),

    // [WORKSPACES + ERROR]: createToastReducer("main:generic_error", toastTypes.error),
    [WORKSPACE + CREATE + ERROR]: createToastReducer(
        "main:generic_request_error",
        toastTypes.error,
        {
            entity: "Workspace",
            action: "creating",
        },
    ),
    [WORKSPACE + UPDATE + ERROR]: createToastReducer(
        "main:generic_request_error",
        toastTypes.error,
        {
            entity: "Workspace",
            action: "renaming",
        },
    ),
    [WORKSPACE + DELETE + ERROR]: createToastReducer(
        "main:generic_request_error",
        toastTypes.error,
        {
            entity: "Workspace",
            action: "deleting",
        },
    ),

    /* Respond with success toast to loaded requests */
    [JOURNEY + CREATE + LOADED]: createToastReducer(
        "main:generic_success",
        toastTypes.success,
        { entity: "Journey", action: "created" },
    ),
    [JOURNEY + DUPLICATE + LOADED]: createToastReducer(
        "main:generic_success",
        toastTypes.success,
        { entity: "Journey", action: "duplicated" },
    ),
    [JOURNEY + DELETE + LOADED](state, payload) {
        const { isRepublishing } = payload?.originalPayload || {}

        if (isRepublishing) {
            return createToastReducer(
                "main:discard_journey_success",
                toastTypes.success,
                {},
                "main:discard_journey_title_success",
            )(state, payload)
        }
        return createToastReducer("main:generic_success", toastTypes.success, {
            entity: "Journey",
            action: "deleted",
        })(state, payload)
    },
    [JOURNEY + UPDATE + LOADED]: createToastReducer(
        "main:rename_journey_success",
        toastTypes.success,
    ),
    [JOURNEY + PUBLISH + LOADED](state, payload) {
        const { isRepublishing } = payload?.originalPayload || {}

        if (isRepublishing) {
            return createToastReducer(
                "main:republish_journey_success",
                toastTypes.success,
            )(state, payload)
        }

        return createToastReducer(
            "main:publish_journey_success",
            toastTypes.success,
        )(state, payload)
    },
    [JOURNEY + UNPUBLISH + LOADED]: createToastReducer(
        "main:unpublish_journey_success",
        toastTypes.success,
    ),
    [JOURNEY + CLONE]: createToastReducer(
        "main:clone_journey",
        toastTypes.warning,
    ),
    [JOURNEY + REPUBLISH + LOADED]: createToastReducer(
        "main:republish_journey_success",
        toastTypes.success,
    ),
    [JOURNEY + MOVE + LOADED]: createToastReducer(
        "main:move_journey_success",
        toastTypes.success,
    ),

    [JOURNEY_EVENT + UPDATE + LOADED]: createToastReducer(
        "main:rename_journey_event_success",
        toastTypes.success,
    ),

    [JOURNEY_EVENT + UPDATE + ERROR]: createToastReducer(
        "main:generic_request_error",
        toastTypes.error,
        {
            entity: "Journey Event",
            action: "renaming",
        },
    ),

    [WORKSPACE + CREATE + LOADED]: createToastReducer(
        "main:generic_success",
        toastTypes.success,
        { entity: "Workspace", action: "created" },
    ),
    [WORKSPACE + UPDATE + LOADED]: createToastReducer(
        "main:generic_success",
        toastTypes.success,
        { entity: "Workspace", action: "renamed" },
    ),
    [WORKSPACE + DELETE + LOADED]: createToastReducer(
        "main:generic_success",
        toastTypes.success,
        { entity: "Workspace", action: "deleted" },
    ),

    /* Respond to modifications of steps */
    [JOURNEY_STEP + CREATE + LOADED]: createToastReducer(
        "main:create_step_success",
        toastTypes.success,
    ),
    [JOURNEY_STEP + DELETE + LOADED]: createToastReducer(
        "main:delete_step_success",
        toastTypes.success,
    ),
    [JOURNEY_STEP + UPDATE + LOADED]: createToastReducer(
        "main:update_step_success",
        toastTypes.success,
    ),
    [JOURNEY_STEP + REORDER + LOADED]: createToastReducer(
        "main:reorder_step_success",
        toastTypes.success,
    ),
    [JOURNEY_STEP + ADD_MESSAGE + LOADED]: createToastReducer(
        "main:add_step_message_success",
        toastTypes.success,
    ),
    [JOURNEY_STEP + ADD_MESSAGE]: createToastReducer(
        "main:add_step_message_success",
        toastTypes.success,
    ),
    [TODO + CREATE + LOADED]: createToastReducer(
        "main:add_step_todo_success",
        toastTypes.success,
    ),
    [TODO + UPDATE + LOADED]: createToastReducer(
        "main:update_step_todo_success",
        toastTypes.success,
    ),
    [TODO + DELETE + LOADED]: createToastReducer(
        "main:delete_step_todo_success",
        toastTypes.success,
    ),
    [TODO + MOVE + ERROR]: createMoveTodoToast,

    [MESSAGE + DELETE + LOADED]: createToastReducer(
        "main:delete_step_message_success",
        toastTypes.success,
    ),
    [MESSAGE + DELETE]: createToastReducer(
        "main:delete_step_message_success",
        toastTypes.success,
    ),
    [DELETE_CONTENT + LOADED]: createToastReducer(
        "main:delete_step_content_success",
        toastTypes.success,
    ),
    [MOVE_CONTENT + ERROR]: createMoveContentToast,

    [JOURNEY_STEP + CREATE + ERROR]: createToastReducer(
        "main:create_step_error",
        toastTypes.error,
    ),
    [JOURNEY_STEP + DELETE + ERROR]: createToastReducer(
        "main:delete_step_error",
        toastTypes.error,
    ),
    [JOURNEY_STEP + UPDATE + ERROR]: createJourneyStepToast,
    [JOURNEY_STEP + REORDER + ERROR]: createToastReducer(
        "main:reorder_step_error",
        toastTypes.error,
    ),
    [JOURNEY_STEP + ADD_MESSAGE + ERROR]: createToastReducer(
        "main:add_step_message_error",
        toastTypes.error,
    ),
    [TODO + CREATE + ERROR]: createToastReducer(
        "main:add_step_todo_error",
        toastTypes.error,
    ),
    [TODO + UPDATE + ERROR]: createToastReducer(
        "main:update_step_todo_error",
        toastTypes.error,
    ),
    [TODO + DELETE + ERROR]: createToastReducer(
        "main:delete_step_todo_error",
        toastTypes.error,
    ),
    [MESSAGE + DELETE + ERROR]: createToastReducer(
        "main:delete_step_message_error",
        toastTypes.error,
    ),
    [DELETE_CONTENT + ERROR]: createToastReducer(
        "main:delete_step_content_error",
        toastTypes.error,
    ),

    /* Respond to load errors from journey edit */
    [JOURNEY_STEPS + ERROR]: createToastReducer(
        "main:generic_error",
        toastTypes.error,
    ),
    [TOPICS + ERROR]: createToastReducer(
        "main:generic_error",
        toastTypes.error,
    ),
    [STORIES + ERROR]: createToastReducer(
        "main:generic_error",
        toastTypes.error,
    ),
    [PAGES + ERROR]: createToastReducer("main:generic_error", toastTypes.error),

    /* Respond to modification of messages */
    [MESSAGE + CREATE + LOADED]: createToastReducer(
        "main:create_message_success",
        toastTypes.success,
    ),
    [MESSAGE + UPDATE + LOADED]: createToastReducer(
        "main:update_message_success",
        toastTypes.success,
    ),

    [MESSAGE + CREATE + ERROR]: createToastReducer(
        "main:create_message_error",
        toastTypes.error,
    ),
    [MESSAGE + UPDATE + ERROR]: createToastReducer(
        "main:update_message_error",
        toastTypes.error,
    ),

    /* Respond to message template modifications */
    [MESSAGE_TEMPLATE + CREATE + LOADED]: createToastReducer(
        "main:create_template_success",
        toastTypes.success,
    ),
    [MESSAGE_TEMPLATE + UPDATE + LOADED]: createToastReducer(
        "main:update_template_success",
        toastTypes.success,
    ),
    [MESSAGE_TEMPLATE + DELETE + LOADED]: createToastReducer(
        "main:delete_template_success",
        toastTypes.success,
    ),

    [MESSAGE_TEMPLATE + CREATE + ERROR]: createToastReducer(
        "main:create_template_error",
        toastTypes.error,
    ),
    [MESSAGE_TEMPLATE + UPDATE + ERROR]: createToastReducer(
        "main:update_template_error",
        toastTypes.error,
    ),
    [MESSAGE_TEMPLATE + DELETE + ERROR](state, payload) {
        const exception = payload?.result?.Reason

        switch (exception) {
            case exceptions.itemIsInUse:
                return createToastReducer(
                    "main:delete_template_error_already_use",
                    toastTypes.error,
                )(state, payload)
            default:
                return createToastReducer(
                    "main:delete_template_error",
                    toastTypes.error,
                )(state, payload)
        }
    },

    // respond to modification of topics

    [TOPIC + CREATE + LOADED]: createToastReducer(
        "main:create_topic_success",
        toastTypes.success,
    ),
    [TOPIC + UPDATE + LOADED]: createToastReducer(
        "main:update_topic_success",
        toastTypes.success,
    ),
    [TOPIC + DELETE + LOADED]: createToastReducer(
        "main:delete_topic_success",
        toastTypes.success,
    ),
    [TOPIC + REORDER + LOADED]: createToastReducer(
        "main:reorder_topic_success",
        toastTypes.success,
    ),

    [TOPIC + CREATE + ERROR]: createToastReducer(
        "main:create_topic_error",
        toastTypes.error,
    ),
    [TOPIC + UPDATE + ERROR]: createToastReducer(
        "main:update_topic_error",
        toastTypes.error,
    ),
    [TOPIC + DELETE + ERROR]: createToastReducer(
        "main:delete_topic_error",
        toastTypes.error,
    ),
    [TOPIC + REORDER + ERROR]: createToastReducer(
        "main:reorder_topic_error",
        toastTypes.error,
    ),

    // respond to modification of stories

    [STORY + CREATE + LOADED]: createToastReducer(
        "main:create_story_success",
        toastTypes.success,
    ),
    [STORY + UPDATE + LOADED]: createToastReducer(
        "main:update_story_success",
        toastTypes.success,
    ),
    [STORY + DELETE + LOADED]: createToastReducer(
        "main:delete_story_success",
        toastTypes.success,
    ),
    [STORY + MOVE + LOADED]: createToastReducer(
        "main:move_story_success",
        toastTypes.success,
    ),
    [STORY + REORDER + LOADED]: createToastReducer(
        "main:reorder_story_success",
        toastTypes.success,
    ),

    [STORY + INTRODUCTION + CREATE + LOADED]: createToastReducer(
        "main:create_story_success",
        toastTypes.success,
    ),

    [STORY + INTRODUCTION + DELETE + LOADED]: createToastReducer(
        "main:delete_story_success",
        toastTypes.success,
    ),

    [STORY + INTRODUCTION + DUPLICATE + LOADED]: createToastReducer(
        "main:create_story_success",
        toastTypes.success,
    ),

    [STORY + INTRODUCTION + CREATE + ERROR]: createToastReducer(
        "main:create_story_error",
        toastTypes.error,
    ),

    [STORY + INTRODUCTION + CREATE + ERROR]: createToastReducer(
        "main:create_story_error",
        toastTypes.error,
    ),

    [STORY + INTRODUCTION + DUPLICATE + ERROR]: createToastReducer(
        "main:create_story_error",
        toastTypes.error,
    ),

    [STORY + CREATE + ERROR]: createToastReducer(
        "main:create_story_error",
        toastTypes.error,
    ),
    [STORY + UPDATE + ERROR]: createToastReducer(
        "main:update_story_error",
        toastTypes.error,
    ),
    [STORY + DELETE + ERROR]: createToastReducer(
        "main:delete_story_error",
        toastTypes.error,
    ),
    [STORY + MOVE + ERROR]: createToastReducer(
        "main:move_story_error",
        toastTypes.error,
    ),
    [STORY + REORDER + ERROR]: createToastReducer(
        "main:reorder_story_error",
        toastTypes.error,
    ),

    // respond to modification of pages

    [PAGE + CREATE + LOADED]: createToastReducer(
        "main:create_page_success",
        toastTypes.success,
    ),
    [PAGE + UPDATE + LOADED]: createToastReducer(
        "main:update_page_success",
        toastTypes.success,
    ),
    [PAGE + DELETE + LOADED]: createToastReducer(
        "main:delete_page_success",
        toastTypes.success,
    ),
    [PAGE + MOVE + LOADED]: createToastReducer(
        "main:move_page_success",
        toastTypes.success,
    ),
    [PAGE + REORDER + LOADED]: createToastReducer(
        "main:reorder_page_success",
        toastTypes.success,
    ),

    [PAGE + CREATE + ERROR]: createToastReducer(
        "main:create_page_error",
        toastTypes.error,
    ),
    [PAGE + UPDATE + ERROR](state, payload) {
        const validationErrors = payload?.result?.ValidationErrors
        const isTextIsTooLong = validationErrors?.some(
            error => error.Value === exceptions.textTooLong,
        )
        if (isTextIsTooLong) {
            return createToastReducer(
                "main:text_is_too_long",
                toastTypes.error,
            )(state, payload)
        }
        return createToastReducer("main:update_page_error", toastTypes.error)(
            state,
            payload,
        )
    },
    [PAGE + DELETE + ERROR]: createToastReducer(
        "main:delete_page_error",
        toastTypes.error,
    ),
    [PAGE + MOVE + ERROR]: createToastReducer(
        "main:move_page_error",
        toastTypes.error,
    ),
    [PAGE + REORDER + ERROR]: createToastReducer(
        "main:reorder_page_error",
        toastTypes.error,
    ),

    // Respond to user management events

    [INVITE + CREATE + LOADED]: createToastReducer(
        "main:invite_create_success",
        toastTypes.success,
    ),
    [USER + DELETE + LOADED]: createToastReducer(
        "main:user_delete_success",
        toastTypes.success,
    ),
    [USER + UPDATE + LOADED]: createToastReducer(
        "main:user_update_success",
        toastTypes.success,
    ),

    [INVITE + CREATE + ERROR](state, payload) {
        const exception = payload?.result?.Type

        switch (exception) {
            case exceptions.emailAlreadyRegistered:
                return createToastReducer(
                    "main:email_already_registered_error",
                    toastTypes.error,
                )(state, payload)
            case exceptions.userAlreadyAssignedThatJourneyException:
                return createToastReducer(
                    "main:email_already_registered_error",
                    toastTypes.error,
                )(state, payload)
            case exceptions.userHasActiveOrPendingManagerRoleException:
                return createToastReducer(
                    "main:user_has_active_or_pending_manager_role",
                    toastTypes.error,
                )(state, payload)
            case exceptions.noFreeSeats:
                return state
            case exceptions.invalidRegionException:
                return createToastReducer(
                    "main:user_has_different_region",
                    toastTypes.error,
                )(state, payload)
            case exceptions.invalidUserRolesException:
                return createToastReducer(
                    "main:user_invalid_role_to_invite",
                    toastTypes.error,
                )(state, payload)
            default:
                return createToastReducer(
                    "main:invite_create_error",
                    toastTypes.error,
                )(state, payload)
        }
    },
    [USER + DELETE + ERROR]: createToastReducer(
        "main:user_delete_error",
        toastTypes.error,
    ),
    [USER + UPDATE + ERROR](state, payload) {
        const exception = payload?.result?.Reason

        switch (exception) {
            case exceptions.emailAlreadyRegistered:
                return createToastReducer(
                    "main:email_already_registered_error",
                    toastTypes.error,
                )(state, payload)
            default:
                return createToastReducer(
                    "main:user_update_error",
                    toastTypes.error,
                )(state, payload)
        }
    },
    [USER + UPDATE + MULTIPLE + USER_RELATIONS + LOADED]: createToastReducer(
        "main:relations_update_success",
        toastTypes.success,
    ),
    [INVITE_RESEND + LOADED]: createToastReducer(
        "main:reminder_create_success",
        toastTypes.success,
    ),
    // Respond to theming requests

    [THEME + CREATE + LOADED]: createToastReducer(
        "main:theme_create_success",
        toastTypes.success,
    ),
    [THEME + UPDATE + LOADED]: createToastReducer(
        "main:theme_update_success",
        toastTypes.success,
    ),
    [THEME + DELETE + LOADED]: createToastReducer(
        "main:theme_delete_success",
        toastTypes.success,
    ),
    [THEME + SELECT + LOADED]: createToastReducer(
        "main:theme_select_success",
        toastTypes.success,
    ),
    [THEME + SELECT]: createToastReducer(
        "main:theme_active_warning",
        toastTypes.warning,
    ),

    [THEMES + ERROR]: createToastReducer(
        "main:generic_request_error",
        toastTypes.error,
        { entity: "Themes", action: "loading" },
    ),
    [THEME + CREATE + ERROR]: createToastReducer(
        "main:theme_create_error",
        toastTypes.error,
    ),
    [THEME + UPDATE + ERROR]: createToastReducer(
        "main:theme_update_error",
        toastTypes.error,
    ),
    [THEME + DELETE + ERROR]: createToastReducer(
        "main:theme_delete_error",
        toastTypes.error,
    ),
    [THEME + SELECT + ERROR]: createToastReducer(
        "main:theme_select_error",
        toastTypes.error,
    ),

    // Respond to glossary item requests

    [GLOSSARY_ITEM + CREATE + LOADED]: createToastReducer(
        "main:glossary_create_success",
        toastTypes.success,
    ),
    [GLOSSARY_ITEM + UPDATE + LOADED]: createToastReducer(
        "main:glossary_update_success",
        toastTypes.success,
    ),
    [GLOSSARY_ITEM + DELETE + LOADED]: createToastReducer(
        "main:glossary_delete_success",
        toastTypes.success,
    ),

    [GLOSSARY_ITEMS + ERROR]: createToastReducer(
        "main:generic_request_error",
        toastTypes.error,
        { entity: "Glossaries", action: "loading" },
    ),
    [GLOSSARY_ITEM + CREATE + ERROR]: createToastReducer(
        "main:glossary_create_error",
        toastTypes.error,
    ),
    [GLOSSARY_ITEM + UPDATE + ERROR]: createToastReducer(
        "main:glossary_update_error",
        toastTypes.error,
    ),
    [GLOSSARY_ITEM + DELETE + ERROR]: createToastReducer(
        "main:glossary_delete_error",
        toastTypes.error,
    ),

    // Respond to tag requests

    [TAG + CREATE + LOADED]: createToastReducer(
        "main:tag_create_success",
        toastTypes.success,
    ),
    [TAG + UPDATE + LOADED]: createToastReducer(
        "main:tag_update_success",
        toastTypes.success,
    ),
    [TAG + DELETE + LOADED]: createToastReducer(
        "main:tag_delete_success",
        toastTypes.success,
    ),

    [TAGS + ERROR]: createToastReducer(
        "main:generic_request_error",
        toastTypes.error,
        { entity: "Tags", action: "loading" },
    ),
    [TAG + CREATE + ERROR]: createToastReducer(
        "main:tag_create_error",
        toastTypes.error,
    ),
    [TAG + UPDATE + ERROR]: createToastReducer(
        "main:tag_update_error",
        toastTypes.error,
    ),
    [TAG + DELETE + ERROR]: createToastReducer(
        "main:tag_delete_error",
        toastTypes.error,
    ),

    // SmartRecruiters requests

    [SMART_RECRUITERS + ACTIVATE]: createToastReducer(
        "main:smartrecruiter_create_success",
        toastTypes.success,
    ),

    [SMART_RECRUITERS + ACTIVATE + ERROR]: createToastReducer(
        "main:generic_request_error",
        toastTypes.error,
        {
            entity: "SmartRecruiters integration",
            action: "activating",
        },
    ),

    [INTEGRATION + ACTIVATE]: createToastReducer(
        "main:integration_activate_success",
        toastTypes.success,
    ),

    [INTEGRATION + ACTIVATE + ERROR]: createToastReducer(
        "main:integration_activate_error",
        toastTypes.error,
    ),

    [INTEGRATION + ACTIVATE + UPDATE]: createToastReducer(
        "main:integration_activate_update_success",
        toastTypes.success,
    ),

    [INTEGRATION + ACTIVATE + UPDATE + ERROR]: createToastReducer(
        "main:integration_activate_error",
        toastTypes.error,
    ),

    [INTEGRATION + ACTIVATE + LOADED]: createToastReducer(
        "main:integration_activate_success",
        toastTypes.success,
    ),

    [INTEGRATION + ACTIVATE + UPDATE + LOADED]: createToastReducer(
        "main:integration_activate_success",
        toastTypes.success,
    ),

    // Profile requests
    [USER + CHANGE_PASSWORD + LOADED]: createToastReducer(
        "main:change_password_success",
        toastTypes.success,
    ),

    [USER + CHANGE_PASSWORD + ERROR]: createToastReducer(
        "main:generic_request_error",
        toastTypes.error,
        {
            entity: "password",
            action: "changing",
        },
    ),

    [EMBEDS + ERROR](state, payload) {
        const exception = payload?.result?.Reason

        switch (exception) {
            case exceptions.embedlyServiceNotAvailable:
                return createToastReducer(
                    "main:embedly_not_available_error",
                    toastTypes.warning,
                )(state, payload)
            default:
                return createToastReducer(
                    "main:generic_request_error",
                    toastTypes.error,
                    { entity: "Embedly", action: "loading" },
                )(state, payload)
        }
    },

    [EMBED + CREATE + ERROR](state, payload) {
        const exception = payload?.result?.Reason

        switch (exception) {
            case exceptions.embedlyServiceNotAvailable:
                return createToastReducer(
                    "main:embedly_not_available_error",
                    toastTypes.warning,
                )(state, payload)
            default:
                return createToastReducer(
                    "main:generic_request_error",
                    toastTypes.error,
                    { entity: "Embedly", action: "creating" },
                )(state, payload)
        }
    },

    // Prerequisites requests
    [PREREQUISITE + CREATE + LOADED]: createToastReducer(
        "main:create_prerequisite_success",
        toastTypes.success,
    ),

    [PREREQUISITE + UPDATE + LOADED]: createToastReducer(
        "main:update_prerequisite_success",
        toastTypes.success,
    ),

    [PREREQUISITE + DELETE]: createPrerequisiteDeleteToast,

    [PREREQUISITE + DELETE + LOADED]: createPrerequisiteDeleteToast,

    [PREREQUISITE + CREATE + ERROR]: createPrerequisiteToast,

    [PREREQUISITE + UPDATE + ERROR]: createPrerequisiteToast,

    [PREREQUISITE + DELETE + ERROR]: createPrerequisiteToast,
}
