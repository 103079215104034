import { themeTypes } from "constants/internal"

export const primaryColor = {
    [themeTypes.dark]: "#242526",
    [themeTypes.light]: "#FFFFFF",
}

export const secondaryColor = {
    [themeTypes.dark]: "#006AFF",
    [themeTypes.light]: "#006AFF",
}

export const cardColor = {
    [themeTypes.dark]: "#2E2E2E",
    [themeTypes.light]: "#8395FF",
}

export const mainBg = {
    [themeTypes.dark]: "#18191a",
    [themeTypes.light]: "#f6f7f8",
}

export const textFieldColor = {
    [themeTypes.dark]: "#090a11",
    [themeTypes.light]: null,
}

export const borderColor = {
    [themeTypes.dark]: null,
    [themeTypes.light]: "#DCE0E4",
}

export const hoverColor = {
    [themeTypes.dark]: null,
    [themeTypes.light]: "#D9E8FF",
}

export const disabledColor = {
    [themeTypes.dark]: "rgba(0, 0, 0, 0.25)",
    [themeTypes.light]: "#BFC5D2",
}

const appicalTheme = {
    // -- SPACING
    spacing: {
        gap: "8px",
        smallest: "16px",
        small: "24px",
        medium: "32px",
        large: "40px",
        largest: "48px",
    },
    sizes: {
        maxContentWidth: "1440px",
        sideMenu: "256px",
        sideBar: "312px",
        elementSideBar: "276px",
        elementSideBarEditMode: "600px",
        mainContent: "720px",
        welcomeImageHeight: "384px",
        welcomeImageHeightMobile: "192px",
        knowledgeBaseCoverImageHeightMobile: "133px",
        tabbarHeight: "60px",
        navbarHeight: "72px",
        userProfileImageSize: 113,
        pagesTopPadding: "40px",
        pagesVideoHeight: "405px",
        formWidth: "360px",
        storyCardWidth: "344px",
        storyImageHeight: "230px",
        introductionStory: "248px",
        chapterImageHeight: "172px",
        chapterCardWidth: "256px",
        journeyCardSize: "328px",
        topicCard: "165px",
        storyCard: "256px",
        playerStoryCardLarge: "280px",
        playerStoryCardMedium: "256px",
        playerStoryCardMobile: "312px",
        pagesMaxWidth: "1024px",
        borderRadius: "8px",
        smallBorderRadius: "4px",
        largeBorderRadius: "8px",
        todoItemsListWidth: "272px",
        toDoItem: "591px",
        navigationBarWidth: "72px",
        journeySidebar: "328px",
        playerNavbarHeight: "72px",
        playerMobileNavbarHeight: "64px",
        smallScreensMaxWidth: "1024px",
        mobileCard: "312px",
        sideBarWidth: "570px",
        registrationBottomBarHeight: "74px",
        glossaryFooterHeight: "95px",
    },

    // -- BASIC
    primary: "#FFFFFF",
    contrast: "#006AFF",
    default: "#006AFF",
    neutral: "#F0F0F0",
    primaryText: "#111111",
    contrastText: "#FFFFFF",
    label: "#878787",
    border: "#DCE0E4",
    disable: "#BFC5D2",
    hover: "#D9E8FF",

    green: "#64e591",

    mainBg: "#FFFFFF",

    success: "#00c48c",
    info: "rgba(41, 123, 232, 1)",
    error: "#ff1f25",
    score: "rgba(245, 166, 35, 1)",
    warning: "#ff7739",
    interactive: "#F97E52",
    questionnaire: "#CB94EE",
    todo: "#80cfff",
    message: "#FFDD18",
    content: "#ff007b",
    card: "#8395FF",

    checkmark: "rgba(156, 206, 124, 1)",
    checkmarkBlue: "rgba(45, 136, 150, 1)",
    checkmarkWhite: "rgba(255, 255, 255, 1)",
    dark: "#111111",
    darkGray: "#323232",
    white: "rgba(255, 255, 255, 1)",
    white10: "rgba(255, 255, 255, 0.1)",
    white20: "rgba(255, 255, 255, 0.2)",
    white35: "rgba(255, 255, 255, 0.35)",
    white50: "rgba(255, 255, 255, 0.5)",
    white70: "rgba(255, 255, 255, 0.7)",
    black: "rgba(0, 0, 0, 1)",
    black10: "rgba(0, 0, 0, 0.1)",
    black20: "rgba(0, 0, 0, 0.2)",
    black35: "rgba(0, 0, 0, 0.35)",
    black70: "rgba(0, 0, 0, 0.7)",
    coral: "#ff6f61",
    gray: "#dce0e4",

    // SOCIAL
    facebookColor: "#3B5998",
    instagramColor: "#833AB4",
    linkedInColor: "#0077B5",
    twitterColor: "#1DA1F2",
    skypeColor: "#00AFF0",

    // Landing Page
    landingPage: {
        colors: {
            blue: "#006AFF",
            lightBlue: "#38C8F1",
            purple: "#8395FF",
            orange: "#FF8A73",
            yellow: "#FFDD18",
        },
    },
}

export const getThemeValueFromType = type => ({
    primary: primaryColor[type],
    contrast: secondaryColor[type],
    card: cardColor[type],
    mainBg: mainBg[type],
    textField: textFieldColor[type],
    border: borderColor[type],
    hover: hoverColor[type],
    disable: disabledColor[type],
})

export default appicalTheme
