import get from "lodash/get"
import { createSelector } from "reselect"

import { outerJoin } from "../../../../lib/utils/helpers/data-sets"

export const selectData = state => get(state, "data")
export const selectIdentifiers = state => get(state, "identifiers")
export const selectIsReady = state => get(state, "isReady")
export const selectLoadingError = state => get(state, "loadingError")
export const selectActiveName = state => get(state, "activeName")
export const selectIsSubdomain = state => get(state, "isSubdomain")

export const selectEnvironments = createSelector(
    selectIdentifiers,
    selectData,
    outerJoin,
)
export const selectActiveEnvironment = createSelector(
    selectData,
    selectActiveName,
    get,
)

export const selectActiveThemeHash = createSelector(
    selectActiveEnvironment,
    environment => get(environment, "themeHash"),
)

export const selectActiveCompanyHash = createSelector(
    selectActiveEnvironment,
    environment => get(environment, "companyHash"),
)
