import { createSelector } from "reselect/lib/index"
import get from "lodash/get"

import {
    convertToMuiTheme,
    fillMissingThemeValues,
    createThemeFromApi,
} from "ThemeProvider/processTheme"
import { getThemeValueFromType } from "ThemeProvider/appicalTheme"
import { createReducer } from "redux/utility"

import { selectData as selectThemeData } from "redux/reducers/entities/themes"
import {
    selectHasMultipleCompanies,
    selectHasMultipleUserTypes,
    selectHasMultipleUserTypesInCurrentCompany,
} from "redux/reducers/entities/users"
import { selectUserInviteCount } from "redux/reducers/entities/userInvites"
import {
    selectData as selectCompanyData,
    selectVisibleCompanies,
} from "redux/reducers/entities/companies"
import { selectSelectedTheme } from "redux/reducers/ui/config"

import { createCoreFeatureSelector } from "../helpers"
import { initialState, reducers } from "./reducers"
import { reducerName } from "./actionNames"
import * as selectors from "./selectors"

export * from "./reducers"

export const selectFeature = createCoreFeatureSelector(reducerName)

export const selectData = createSelector(selectFeature, selectors.selectData)
export const selectIdentifiers = createSelector(
    selectFeature,
    selectors.selectIdentifiers,
)
export const selectIsReady = createSelector(
    selectFeature,
    selectors.selectIsReady,
)
export const selectLoadingError = createSelector(
    selectFeature,
    selectors.selectLoadingError,
)
export const selectActiveName = createSelector(
    selectFeature,
    selectors.selectActiveName,
)

export const selectIsSubdomain = createSelector(
    selectFeature,
    selectors.selectIsSubdomain,
)

export const selectEnvironments = createSelector(
    selectFeature,
    selectors.selectEnvironments,
)

export const selectActiveEnvironment = createSelector(
    selectFeature,
    selectors.selectActiveEnvironment,
)

export const selectActiveThemeHash = createSelector(
    selectFeature,
    selectors.selectActiveThemeHash,
)

export const selectActiveCompanyHash = createSelector(
    selectFeature,
    selectors.selectActiveCompanyHash,
)

export const selectActiveTheme = createSelector(
    selectThemeData,
    selectActiveThemeHash,
    get,
)
export const selectActiveMUITheme = createSelector(
    selectActiveTheme,
    selectSelectedTheme,
    selectIsSubdomain,
    (companyTheme, selectedThemeType, isSubdomain) => {
        const currentThemeValues = getThemeValueFromType(selectedThemeType)
        const currentTheme = convertToMuiTheme(
            fillMissingThemeValues(currentThemeValues),
        )

        return isSubdomain ? createThemeFromApi(companyTheme) : currentTheme
    },
)

export const selectPlayerMUITheme = createSelector(
    selectActiveTheme,
    selectSelectedTheme,
    selectIsSubdomain,
    (companyTheme, selectedThemeType, isSubdomain) => {
        const currentThemeValues = getThemeValueFromType(selectedThemeType)
        const currentTheme = convertToMuiTheme(
            fillMissingThemeValues(currentThemeValues),
        )

        return isSubdomain || companyTheme
            ? createThemeFromApi(companyTheme)
            : currentTheme
    },
)

export const selectEditorMUITheme = createSelector(
    selectSelectedTheme,
    selectedThemeType => {
        const currentThemeValues = getThemeValueFromType(selectedThemeType)
        const currentTheme = convertToMuiTheme(
            fillMissingThemeValues(currentThemeValues),
        )

        return currentTheme
    },
)

export const selectPreviewMUITheme = createSelector(
    selectActiveTheme,
    companyTheme => createThemeFromApi(companyTheme),
)

export const selectActiveCompany = createSelector(
    selectCompanyData,
    selectActiveCompanyHash,
    get,
)

export const selectActiveThemeImageUrl = createSelector(
    selectActiveTheme,
    theme => get(theme, "imageUrl"),
)

export const selectEnvironmentCompanies = createSelector(
    selectVisibleCompanies,
    selectActiveCompany,
    selectIsSubdomain,
    (companies, activeCompany, isSubdomain) => {
        const companyHash = get(activeCompany, "hash")

        return isSubdomain && companies.length
            ? [companies.find(company => company.hash === companyHash)]
            : companies
    },
)

export const selectAllowSwitchEnvironment = createSelector(
    selectUserInviteCount,
    selectHasMultipleCompanies,
    selectIsSubdomain,
    selectHasMultipleUserTypes,
    selectHasMultipleUserTypesInCurrentCompany,
    (
        inviteCount,
        hasMultipleCompanies,
        isSubdomain,
        hasMultipleUserTypes,
        hasMultipleUserTypesInCurrentCompany,
    ) =>
        (!!inviteCount && !isSubdomain) ||
        (hasMultipleCompanies && !isSubdomain) ||
        (hasMultipleUserTypes && !isSubdomain) ||
        (hasMultipleUserTypesInCurrentCompany && isSubdomain),
)

export const reducer = createReducer(reducers, initialState)
