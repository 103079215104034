import { SELECT } from "redux/utility"

import { ENVIRONMENT } from "./actionNames"
import { selectData } from "."

export const createLoadingAction = name => ({
    type: ENVIRONMENT,
    request: {
        method: "GET",
        url: `/editor/Company/Settings/${name}`,
    },
    payload: { name },
})

export const createSelectEnvironmentAction = (
    name,
    isSubdomain,
    isReady = false,
) => ({
    type: ENVIRONMENT + SELECT,
    payload: { name, isReady, isSubdomain },
})

export const createSelectLoadEnvironmentAction = (
    name,
    isSubdomain = false,
) => (dispatch, getState) => {
    const state = getState()
    const hasEnvironment = !!selectData(state)?.name

    if (name && !hasEnvironment) {
        dispatch(createLoadingAction(name))
    }

    dispatch(createSelectEnvironmentAction(name, isSubdomain, hasEnvironment))
}
