import first from "lodash/first"

import { ERROR, LOADED, LOADING } from "redux/middleware/actionNames"
import { SELECT, CREATE } from "redux/utility"

import { mapEnvironment } from "redux/reducers/entities/companies/helpers"

import { LOGOUT } from "redux/reducers/core/authentication/actionNames"
import { USER } from "redux/reducers/entities/users/actionNames"
import { COMPANY } from "redux/reducers/entities/companies/actionNames"
import { THEME } from "redux/reducers/entities/themes/actionNames"
import { ENVIRONMENT } from "./actionNames"
import {
    selectData,
    selectIdentifiers,
    selectActiveName,
    selectIsSubdomain,
} from "./selectors"

//  Required variables
export const initialState = {
    /* Holds the different environments */
    data: {},
    /* Holds the different environment names */
    identifiers: [],
    /* Holds the flag whether the environment is loading */
    isReady: false,
    /* Holds the last load error from the server */
    loadingError: null,
    /* Holds whether the environment has been extracted from a subdomain domain */
    isSubdomain: false,
    /* Holds the currently active environment name */
    activeName: null,
}

// Reducer
export const reducers = {
    [ENVIRONMENT + LOADING](state) {
        return { ...state, loadingError: null }
    },

    [ENVIRONMENT + LOADED](
        state,
        { result: { company, theme }, originalPayload: { name } },
    ) {
        return {
            ...state,
            isReady: state.isReady || state.activeName === name,
            data: {
                ...state.data,
                [name]: {
                    companyHash: company.companyHash,
                    themeHash: theme.hash,
                },
            },
            identifiers: [...state.identifiers, name],
        }
    },

    [ENVIRONMENT + ERROR](state, { result: loadingError }) {
        return { ...state, isReady: true, loadingError }
    },

    [ENVIRONMENT + SELECT](state, { name: activeName, isReady, isSubdomain }) {
        return {
            ...state,
            activeName,
            isReady,
            isSubdomain,
            loadingError: null,
        }
    },

    [COMPANY + CREATE + LOADED](
        state,
        { result: { hash: companyHash }, originalPayload },
    ) {
        const data = selectData(state)
        const identifiers = selectIdentifiers(state)
        const { environment: name } =
            first(mapEnvironment([originalPayload])) || {}

        return {
            ...state,
            data: {
                ...data,
                [name]: {
                    companyHash,
                    themeHash: null,
                },
            },
            identifiers: [...identifiers, name],
            activeName: name,
            isReady: true,
        }
    },

    [USER + CREATE + LOADED](state, { result: { companies } }) {
        if (!companies?.length || companies?.length !== 1) {
            return state
        }

        const mappedCompaniesData = mapEnvironment(companies)
        const activeName = mappedCompaniesData?.[0]?.environment

        if (activeName === state.activeName) return { ...state }

        const data = selectData(state)
        const identifiers = selectIdentifiers(state)
        const companyHash = mappedCompaniesData?.[0]?.hash
        const currentCompany = data[activeName]

        return {
            ...state,
            data: {
                ...data,
                [activeName]: {
                    companyHash,
                    ...currentCompany,
                },
            },
            identifiers: [...identifiers, activeName],
            activeName,
            isReady: true,
        }
    },

    [THEME + SELECT + LOADED](
        state,
        {
            originalPayload: {
                theme: { hash: themeHash },
            },
        },
    ) {
        const activeName = selectActiveName(state)
        const data = selectData(state)
        const { [activeName]: environment } = data

        return {
            ...state,
            data: {
                ...data,
                [activeName]: {
                    ...environment,
                    themeHash,
                },
            },
        }
    },

    // reset reducer

    [COMPANY + SELECT](state, { selectedHash: companyHash }) {
        const isSubdomain = selectIsSubdomain(state)

        if (!companyHash && !isSubdomain) {
            return {
                ...state,
                activeName: null,
                isReady: false,
            }
        }

        return state
    },

    [LOGOUT + LOADED](state) {
        const isSubdomain = selectIsSubdomain(state)

        return isSubdomain ? state : initialState
    },
}
