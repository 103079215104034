import once from "lodash/once"

import client from "configure/client"
import { getFullDomain } from "lib/utils/helpers/uri"

import {
    hasCredentialMgmt,
    preventAccess,
    setCurrentCredentialData,
} from "lib/utils/helpers/credential-mgmt"

import { createLoadAndNotify as createLoadInvitesAndNotify } from "redux/reducers/entities/userInvites/actions"

import {
    LOGIN,
    LOGOUT,
    SET_REDIRECT,
    REFRESH,
    REFRESHING,
    VALIDATE_EMAIL,
    RESET_PASSWORD,
    CHANGE_PASSWORD,
} from "./actionNames"
import { selectIsSubdomain } from "../environment"
import { selectAuthToken } from "./index"

// Action creators
export function createRedirectUrlAction(url) {
    return { type: SET_REDIRECT, payload: url }
}

export function createRefreshingAction() {
    return { type: REFRESHING }
}

export function createRefreshAction(authentication) {
    return { type: REFRESH, payload: authentication }
}
export const createLoginAction = (formData, callback = null) => ({
    type: LOGIN,
    request: {
        method: "post",
        url: "/editor/Authentication/SignIn",
        data: {
            ...formData,
            DeviceName: getFullDomain(),
            DeviceType: "web",
        },
    },
    payload: formData,
    callback: (...params) => {
        if (hasCredentialMgmt) {
            setCurrentCredentialData(formData.username, formData.password)
        }

        if (callback) callback(...params)
    },
})

export const logout = () => (dispatch, getState) => {
    if (hasCredentialMgmt) preventAccess()

    const isSubdomain = selectIsSubdomain(getState())

    dispatch({
        type: LOGOUT,
        request: {
            method: "POST",
            url: "/editor/User/SignOut",
        },
        payload: { isSubdomain },
    })
}

function executeTokenRefresh(refreshToken, dispatch, state) {
    const currentToken = selectAuthToken(state)
    const postData = {
        refreshToken,
    }

    dispatch(createRefreshingAction())

    return client
        .request({
            method: "POST",
            url: "/editor/Authentication/RefreshToken",
            data: postData,
            headers: {
                "X-Client-Type": "web",
                "X-Client-Version": process.env.REACT_APP_VERSION,
            },
        })
        .then(result => {
            dispatch(createRefreshAction(result.data))
            dispatch(createLoadInvitesAndNotify())
        })
        .catch(() => {
            if (currentToken !== selectAuthToken(state)) {
                // The token was already refreshed by another
                // request triggered at the same time.
                return
            }

            // Token refresh failed. Log the user out
            dispatch(logout())
        })
}

let refreshFunction = null

export function tokenRefresh(refreshToken) {
    return (dispatch, state) => {
        if (!refreshToken) return

        if (!refreshFunction) {
            // Ensure we only send one token refresh call at a time
            refreshFunction = once(executeTokenRefresh)
        }

        refreshFunction(refreshToken, dispatch, state)
            .then(() => {
                refreshFunction = null
            })
            .catch(() => {
                refreshFunction = null
            })
    }
}

export const createResetPasswordAction = ({ emailAddress }, callback) => ({
    type: RESET_PASSWORD,
    request: {
        method: "POST",
        url: "/editor/Authentication/ForgotPassword",
        data: { emailAddress },
    },
    payload: { emailAddress },
    callback,
})

export const createChangePasswordAction = (
    { resetKey, password, passwordConfirmation },
    callback,
) => ({
    type: CHANGE_PASSWORD,
    request: {
        method: "POST",
        url: "/editor/Authentication/ChangePasswordWithToken",
        data: {
            resetKey,
            password,
            passwordConfirmation,
        },
    },
    callback,
})

export const createValidateEmailAction = (activationKey, callback = null) => ({
    type: VALIDATE_EMAIL,
    request: {
        method: "POST",
        url: "/editor/UserInvite/ValidateEmailAddress",
        data: {
            activationKey,
        },
    },
    callback,
})
