import get from "lodash/get"

// Selectors
export const selectData = state => get(state, "data")
export const selectIsLoggedIn = state => !!get(state, "data.token")
export const selectAuthToken = state => get(state, "data.token")
export const selectRefreshToken = state => get(state, "data.refreshToken")
export const selectIsRefreshingAuthToken = state =>
    get(state, "isRefreshingAuthToken")
export const selectEmailChecked = state => get(state, "emailChecked")
export const selectSsoData = state => get(state, "ssoData")
export const selectConfirmationEmailSent = state =>
    get(state, "confirmationEmailSent")
export const selectRedirectUrl = state => get(state, "redirectUrl")
export const selectIsLoading = state => get(state, "isLoading")
export const selectLoadingError = state => get(state, "loadingError")
export const selectHasConfirmedEmail = state =>
    get(state, "data.hasConfirmedEmail", false)
export const selectDidLogout = state => get(state, "didLogout")
export const selectCurrentEmail = state => get(state, "currentEmail")
