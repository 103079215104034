import { createSelector } from "reselect"

import { createReducer } from "redux/utility"
import { createEntitiesFeatureSelector } from "../helpers"

import { reducers, initialState } from "./reducers"
import * as selectors from "./selectors"
import { reducerName } from "./actionNames"

export * from "./reducers"

export const selectFeature = createEntitiesFeatureSelector(reducerName)

export const selectData = createSelector(selectFeature, selectors.selectData)
export const selectIdentifiers = createSelector(
    selectFeature,
    selectors.selectIdentifiers,
)
export const selectToasts = createSelector(
    selectData,
    selectIdentifiers,
    (messages, identifiers) => identifiers?.map(id => messages[id]),
)

// Reducer
export const reducer = createReducer(reducers, initialState)
