import { createActionName } from "../../../utility"

export const reducerName = "authentication"

// Actions
export const SET_REDIRECT = createActionName(reducerName, "SET_REDIRECT")
export const SET_SSO_RESPONSE = createActionName(
    reducerName,
    "SET_SSO_RESPONSE",
)
export const DROP_SSO_RESPONSE = createActionName(
    reducerName,
    "DROP_SSO_RESPONSE",
)
export const LOGIN = createActionName(reducerName, "LOGIN")
export const LOGOUT = createActionName(reducerName, "LOGOUT")
export const REFRESHING = createActionName(reducerName, "REFRESHING")
export const REFRESH = createActionName(reducerName, "REFRESH")
export const RESET_PASSWORD = createActionName(reducerName, "RESET_PASSWORD")
export const CHANGE_PASSWORD = createActionName(reducerName, "CHANGE_PASSWORD")

export const VALIDATE_EMAIL = createActionName(reducerName, "VALIDATE_EMAIL")
export const INVALIDATE_TOKEN = createActionName(
    reducerName,
    "INVALIDATE_TOKEN",
)
