import { createSelector } from "reselect/lib/index"

import { createReducer } from "redux/utility"
import { createCoreFeatureSelector } from "../helpers"

import { reducers, initialState } from "./reducers"
import { reducerName } from "./actionNames"

import * as selectors from "./selectors"

// Action creators
export * from "./reducers"

export const selectFeature = createCoreFeatureSelector(reducerName)

export const selectIsLoggedIn = createSelector(
    selectFeature,
    selectors.selectIsLoggedIn,
)
export const selectAuthToken = createSelector(
    selectFeature,
    selectors.selectAuthToken,
)
export const selectRefreshToken = createSelector(
    selectFeature,
    selectors.selectRefreshToken,
)
export const selectIsRefreshingAuthToken = createSelector(
    selectFeature,
    selectors.selectIsRefreshingAuthToken,
)
export const selectEmailChecked = createSelector(
    selectFeature,
    selectors.selectEmailChecked,
)
export const selectSsoData = createSelector(
    selectFeature,
    selectors.selectSsoData,
)
export const selectConfirmationEmailSent = createSelector(
    selectFeature,
    selectors.selectConfirmationEmailSent,
)
export const selectRedirectUrl = createSelector(
    selectFeature,
    selectors.selectRedirectUrl,
)
export const selectIsLoading = createSelector(
    selectFeature,
    selectors.selectIsLoading,
)
export const selectLoadingError = createSelector(
    selectFeature,
    selectors.selectLoadingError,
)

export const selectCurrentEmail = createSelector(
    selectFeature,
    selectors.selectCurrentEmail,
)
export const selectHasConfirmedEmail = createSelector(
    selectFeature,
    selectors.selectHasConfirmedEmail,
)
export const selectDidLogout = createSelector(
    selectFeature,
    selectors.selectDidLogout,
)

export const reducer = createReducer(reducers, initialState)
