import { userRoles, companyRoles, roleStates } from "constants/enums"
import exceptions from "constants/exceptions"
import { LOADED, LOADING, ERROR } from "redux/middleware/actionNames"
import { CREATE, UPDATE } from "redux/utility"

import { USER } from "redux/reducers/entities/users/actionNames"
import { COMPANY } from "redux/reducers/entities/companies/actionNames"

import {
    LOGIN,
    LOGOUT,
    SET_REDIRECT,
    DROP_SSO_RESPONSE,
    SET_SSO_RESPONSE,
    REFRESH,
    REFRESHING,
    INVALIDATE_TOKEN,
    VALIDATE_EMAIL,
    RESET_PASSWORD,
    CHANGE_PASSWORD,
} from "./actionNames"
import { selectData } from "./selectors"

//  Required variables
export const initialState = {
    /* Holds the user redux object */
    data: null,
    /* Retains if we checked the users email for sso */
    emailChecked: null,
    /* If the user is an sso user we can open the sso flow */
    ssoData: null,
    /* Indicates whether the confirmation email has been sent or not */
    confirmationEmailSent: false,
    /* The url to redirect to after a successful login */
    redirectUrl: "/",
    /* Indicates that we are refreshing the token */
    isRefreshingAuthToken: false,
    /* Are we loading new checklist items */
    isLoading: false,
    /* Loading checklist items resulted in an error */
    loadingError: null,
    /* Indicates whether the user was previously logged in before accessing the platform */
    didLogout: false,
}

// Reducer
export const reducers = {
    [SET_REDIRECT](state, payload) {
        if (state.data) {
            return state
        }
        return { ...state, redirectUrl: payload }
    },
    [SET_SSO_RESPONSE](state, payload) {
        if (state.data) {
            return state
        }
        return { ...state, emailChecked: payload.email, ssoData: payload.data }
    },
    [DROP_SSO_RESPONSE](state) {
        if (state.data) {
            return state
        }
        return { ...state, emailChecked: null, ssoData: null }
    },

    [LOGIN + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },

    [LOGIN + LOADED](state, payload) {
        const {
            result: { hasConfirmedEmail, ...data },
        } = payload
        const confirmationEmailSent = hasConfirmedEmail

        return {
            ...state,
            data: {
                ...data,
                hasConfirmedEmail,
            },
            isLoading: false,
            emailChecked: null,
            confirmationEmailSent,
            ssoData: null,
            didLogout: false,
        }
    },

    [LOGIN + ERROR](state, payload) {
        if (!payload.result.Reason) {
            return {
                ...state,
                isLoading: false,
                loadingError: exceptions.noResponse,
            }
        }

        return {
            ...state,
            isLoading: false,
            loadingError: payload.result,
        }
    },

    [REFRESHING](state) {
        return {
            ...state,
            refreshingAuthToken: true,
        }
    },
    [REFRESH](state, payload) {
        return {
            ...state,
            data: { ...state.data, ...payload },
            refreshingAuthToken: false,
        }
    },

    [VALIDATE_EMAIL + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },

    [VALIDATE_EMAIL + LOADED](state, { result: authData }) {
        const data = selectData(state)

        return {
            ...state,
            data: {
                ...data,
                ...authData,
                hasConfirmedEmail: true,
                subscription: null,
            },
            isLoading: false,
            confirmationEmailSent: true,
        }
    },

    [RESET_PASSWORD + LOADING](state) {
        return { ...state, isLoading: true }
    },

    [RESET_PASSWORD + LOADED](state) {
        return { ...state, isLoading: false }
    },

    [RESET_PASSWORD + ERROR](state, { result: loadingError }) {
        return { ...state, isLoading: true, loadingError }
    },

    [CHANGE_PASSWORD + LOADING](state) {
        return { ...state, isLoading: true }
    },

    [CHANGE_PASSWORD + LOADED](state) {
        return { ...state, isLoading: false }
    },

    [CHANGE_PASSWORD + ERROR](state, { result: loadingError }) {
        return { ...state, isLoading: false, loadingError }
    },

    [VALIDATE_EMAIL + ERROR](state, { result: loadingError }) {
        return { ...state, isLoading: false, loadingError }
    },

    [USER + CREATE + LOADED](
        state,
        {
            result: {
                token,
                refreshToken,
                companies,
                hasAgreedToAllPolicies,
                hasConfirmedEmail,
            },
        },
    ) {
        const oldData = selectData(state)
        let data = null

        if (token) {
            data = {
                ...oldData,
                token,
                refreshToken,
                companies,
                hasConfirmedEmail,
                hasAgreedToAllPolicies,
            }
        }

        return {
            ...state,
            data,
            isLoading: false,
            confirmationEmailSent: true,
            didLogout: false,
        }
    },

    [USER + UPDATE + LOADED](
        state,
        { originalPayload: { isLoggedInUser, hasConfirmedEmail } },
    ) {
        if (!isLoggedInUser) return state

        const data = selectData(state)

        return {
            ...state,
            data: {
                ...data,
                hasConfirmedEmail,
            },
        }
    },
    [COMPANY + CREATE + LOADED](state, { result: { hash }, originalPayload }) {
        const data = selectData(state)
        const newCompany = {
            hash,
            roles: [{ role: userRoles.owner, status: roleStates.active }],
            companyRoleType: companyRoles.clientCompany,
            ...originalPayload,
        }

        return {
            ...state,
            data: {
                ...data,
                companies: [...(data.companies || []), newCompany],
            },
        }
    },

    // Reset reducers
    [LOGOUT + LOADED]() {
        return {
            ...initialState,
            didLogout: true,
        }
    },

    // DEBUG
    [INVALIDATE_TOKEN](state) {
        return {
            ...state,
            data: {
                ...state.data,
                access_token: "INVALID",
            },
        }
    },
}
